html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --container-sm: 666px;
  --container-lg: 1312px;
}
