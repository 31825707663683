@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog[data-template='slideup'] {
    max-width: 1200px !important;
    min-width: auto !important;
    width: 90% !important;
  }
  #CybotCookiebotDialog[data-template='slideup'].CybotCookiebotDialogActive {
    transform: translate(-50%, -130%) !important;
  }
  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogHeader {
    width: 160px !important;
  }
  #CybotCookiebotDialog
    #CybotCookiebotDialogBody
    .CybotCookiebotScrollContainer {
    max-height: 12.5em !important;
  }
  #CybotCookiebotDialog.CybotEdge
    #CybotCookiebotDialogBodyLevelButtonsSelectPane
    .CybotCookiebotDialogBodyLevelButtonWrapper {
    margin: 0.5em 1em 0 0 !important;
  }
  #CybotCookiebotDialogBodyLevelButtonsSelectPane
    .CybotCookiebotDialogBodyLevelButtonDescription {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1510px) {
  #CybotCookiebotDialog.CybotEdge:not([lang='ta']):not([lang='bg']):not([lang='is']):not([lang='el'])
    #CybotCookiebotDialogTabContent {
    margin: 0 0 0 1.5em !important;
  }
  #CybotCookiebotDialog.CybotEdge:not([lang='ta']):not([lang='bg']):not([lang='is']):not([lang='el'])
    #CybotCookiebotDialogFooter {
    position: absolute !important;
  }
  #CybotCookiebotDialog.CybotEdge:not([lang='ta']):not([lang='bg']):not([lang='is']):not([lang='el'])
    .CybotCookiebotScrollContainer {
    width: calc(100% - 286px - 1.5em) !important;
  }
}
